import { combineReducers } from 'redux';

import { alert } from './alert.reducer';
import { auth } from './auth.reducer';
import { pageInfo } from './page_info.reducer';

const rootReducer = combineReducers({
  alert,
  auth,
  pageInfo,
});

export default rootReducer;