import { BASE_API } from 'config'

const API = {
    // Authentication
    'LOGIN': BASE_API + '/login',
    'REGISTER': BASE_API + '/register',

    // Homepage
    'FETCH_PAGE_INFO': BASE_API + '/website-info',
    'SEARCH_PLACE': 'https://maps.googleapis.com/maps/api/geocode/json',
    'FETCH_LIST_PLACE': BASE_API + '/locations/user-get-all',
    'FETCH_PLACE_DETAIL': BASE_API + '/locations/v2/get-location-by-lat-long-3rd',
    'VALIDATE_LOCATION': BASE_API + '/locations/validate',
}

export default API;