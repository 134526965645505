import axios from 'axios'

export default class Request {

    // constructor() {
    //     // config defaults
    //     axios.defaults.withCredentials = true;
    // }

    cancel = () => {
        if (this.source) {
            this.source.cancel('')
        }
    }

    get = async (url, data) => {

        const CancelToken = axios.CancelToken;
        this.source = CancelToken.source();

        let config = {
            cancelToken: this.source.token
        };
        if (data) {
            config['params'] = data
        }

        return await axios.get(url, config)
            .then(function (response) {
                // handle success
                // console.log(response);
                return response.data;
            })
            .catch(function (thrown) {
                if (axios.isCancel(thrown)) {
                    console.log('canceled', thrown.message);
                } else {
                    // handle error
                    console.log(thrown);
                    return {
                        'status': 500,
                        'content': 'Kết nối đến server thất bại',
                        'data': {}
                    };
                }
            })
            .finally(function () {
                // always executed
            });
    };

    post = async (url, data, configs = {}) => {
        const CancelToken = axios.CancelToken;
        this.source = CancelToken.source();

        let config = {
            cancelToken: this.source.token,
            ...configs
        };

        return await axios.post(url, data, config)
            .then(function (response) {
                // handle success
                // console.log(response);
                return response.data;
            })
            .catch(function (thrown) {
                if (axios.isCancel(thrown)) {
                    console.log('canceled', thrown.message);
                } else {
                    // handle error
                    console.log(thrown);
                    return {
                        'status': 500,
                        'content': 'Kết nối đến server thất bại',
                        'data': {}
                    };
                }
            })
            .finally(function () {
                // always executed
            });
    }
}
