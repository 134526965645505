import React from "react";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/swiper.min.css";
import "swiper/components/pagination/pagination.min.css"

import "./Slider.scss";

// import Swiper core and required modules
import SwiperCore, { Pagination } from 'swiper/core';

// install Swiper modules
SwiperCore.use([Pagination]);

const Slider = props => {
    const { list, renderItem, overrideConfig } = props;
    if (!list || list.length === 0) {
        return null;
    }

    const sliderItems = list.map((item, index) => <SwiperSlide key={index}>{renderItem(item, index)}</SwiperSlide>);

	const pagination = {
		"clickable": true,
		"renderBullet": function (index, className) {
			return (
				'<span class="' + className + '"></span>'
			);
		}
	}


	return (
		<>
			<Swiper pagination={pagination} spaceBetween={24} {...overrideConfig}>
				{sliderItems}
			</Swiper>
		</>
	)
}

export default Slider;