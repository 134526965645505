import { API, Request } from 'network';
import { uuidv4 } from 'utils/AppHelper';

const request = new Request();

const login = (mobile, password) => {
    let data = new FormData();
    data.append('mobile', mobile);
    data.append('password', password);
    data.append('session_uuid', uuidv4());

    request.cancel();
    return request.post(API.LOGIN, data)
        .then(result => {
            // return fake data
            return {
                status: 200,
                data: {
                    user_oid: '5b92418abcefae2a138b45b5',
                    user_id: 1,
                    avatar: '',
                    cover: '',
                    fullname: 'Tran Bao Tuan',
                    mobile: '0384822466',
                    email: '',
                    token: 'ceb613f005bf899adcae920e70abff2264f88e05'
                }
            }
            // end return fake data
            
            try {
                if (result.status === 200) {
                    return result.data;
                } else {
                    console.log('API ' + API.LOGIN + 'lỗi status: ' + result.status + ', nội dung: ' + result.content)
                    return result.content;
                }
            } catch (error) {
                console.log('API ' + API.LOGIN + ' lỗi trycatch: ' + error);
                return null;
            }
        });
}

const register = (form_data) => {
    let data = new FormData();
    Object.keys(form_data).forEach(key => data.append(key, form_data[key]));

    let request = new Request();
    return request.post(API.REGISTER, data)
        .then(result => {
            try {
                if (result.status === 200) {
                    return result.status;
                } else {
                    console.log('API ' + API.REGISTER + 'lỗi status: ' + result.status + ', nội dung: ' + result.content)
                    return result.content;
                }
            } catch (error) {
                console.log('API ' + API.REGISTER + ' lỗi trycatch: ' + error);
                return null;
            }
        });
}

export const authService = {
    login,
    register,
}