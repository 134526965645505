import { API, Request } from 'network';
import { GG_MAPS_KEY } from 'config';

const fetch_list_place = (position, country) => {
    let params = { position, country }

    const request = new Request();
    return request.get(API.FETCH_LIST_PLACE)
        .then(result => {
            // console.log('??????', result);
            try {
                let filtered_result = result;
                // let filtered_result = result.filter(item => item.country === country);
                if (filtered_result.length > 0) {
                    return filtered_result;
                } else {
                    console.log('API ' + API.FETCH_LIST_PLACE + ' lỗi status: ' + result.status + ', nội dung: ' + result.error_message)
                    return null;
                }
            } catch (error) {
                console.log('API ' + API.FETCH_LIST_PLACE + ' lỗi trycatch: ' + error);
                return null;
            }
        });
}

const fetch_place_detail = (position) => {
    let request = new Request();
    return request.post(API.FETCH_PLACE_DETAIL, position)
        .then(result => {
            try {
                if (result) {
                    return result;
                } else {
                    console.log('API ' + API.FETCH_PLACE_DETAIL + 'lỗi status: ' + result.status + ', nội dung: ' + result.content)
                    return false;
                }
            } catch (error) {
                console.log('API ' + API.FETCH_PLACE_DETAIL + ' lỗi trycatch: ' + error);
                return null;
            }
        });
}

const search_place = (address) => {
    let params = { 
        address,
        key: GG_MAPS_KEY,
        auth_referrer_policy: 'origin'
    }

    const request = new Request();
    return request.get(API.SEARCH_PLACE, params)
        .then(result => {
            console.log('??????', result);
            try {
                if (result.status === 'OK') {
                    return result.results;
                } else {
                    console.log('API ' + API.SEARCH_PLACE + ' lỗi status: ' + result.status + ', nội dung: ' + result.error_message)
                    return null;
                }
            } catch (error) {
                console.log('API ' + API.SEARCH_PLACE + ' lỗi trycatch: ' + error);
                return null;
            }
        });
}

const validate_location = (position) => {
    let request = new Request();
    return request.post(API.VALIDATE_LOCATION, position)
        .then(result => {
            try {
                if (result === true) {
                    return true;
                } else {
                    console.log('API ' + API.VALIDATE_LOCATION + 'lỗi status: ' + result.status + ', nội dung: ' + result.content)
                    return false;
                }
            } catch (error) {
                console.log('API ' + API.VALIDATE_LOCATION + ' lỗi trycatch: ' + error);
                return null;
            }
        });
}

export const homepageService = {
    search_place,
    fetch_list_place,
    fetch_place_detail,
    validate_location
}