import { alertConstants } from 'app/redux/constants';

let success = message => {
    return { type: alertConstants.SUCCESS, message };
}

let error = message => {
    return { type: alertConstants.ERROR, message };
}

let clear = id => {
    return { type: alertConstants.CLEAR, id };
}

export const alertActions = {
    success,
    error,
    clear
}