import { useEffect } from "react";
import PropTypes from "prop-types";

import * as L from "leaflet";

import {
    useMap,
} from 'react-leaflet';

import 'leaflet.markercluster';
import 'leaflet.markercluster/dist/MarkerCluster.css';
import 'leaflet.markercluster/dist/MarkerCluster.Default.css';

import './MarkerCluster.scss';

import { homepageService } from 'services';

function updateProgressBar(processed, total, elapsed, layersArray) {
	if (elapsed > 1000) {
		// if it takes more than a second to load, display the progress bar:
		// progress.style.display = 'block';
		// progressBar.style.width = Math.round(processed/total*100) + '%';
		console.log(Math.round(processed/total*100) + '%');
	}

	if (processed === total) {
		// all markers processed - hide the progress bar:
		// progress.style.display = 'none';
	}
}

const mcg = L.markerClusterGroup({ chunkedLoading: true, chunkProgress: updateProgressBar, disableClusteringAtZoom: 17 });

const marker_nft = L.icon({
    iconUrl: 'public_assets/images/map-marker-nft.png',
    iconSize: [56, 74],
    iconAnchor: [28, 56],
    popupAnchor: [0, -42]
});

const marker_blacklist = L.icon({
    iconUrl: 'public_assets/images/map-marker-blacklist.png',
    iconSize: [64, 70],
    iconAnchor: [32, 53],
    popupAnchor: [0, -40]
});

function MarkerCluster({ markers }) {
	const map = useMap();

	useEffect(() => {
		if (markers && markers.length > 0) {
			mcg.clearLayers();

			let markerList = [];
			markers.forEach((item) => {
				if (
					!item.lat 
					|| !item.long 
					|| parseFloat(item.lat) < -90 
					|| parseFloat(item.lat) > 90
					|| parseFloat(item.long) < -180
					|| parseFloat(item.long) > 180
				) {
					console.log('Invalid location data! ', item.id, item.lat, item.long, item.name);
					return null;
				}

				let popup = L.popup();

				let marker = L.marker(new L.LatLng(item.lat, item.long), {
					icon: item.is_blacklist ? marker_blacklist : marker_nft
				})
					.bindPopup(popup)
					.on('popupopen', () =>  {
						homepageService.fetch_place_detail({ lat: item.lat, long: item.long })
							.then(item => {
								console.log(item);
				
								let fullname = item.user_id
										? item.user_id.full_name
										: 'Người mua ẩn danh';
								fullname = item.is_blacklist ? 'Black List' : fullname;
				
								let img_path = item.map_captured
									? item.map_captured.includes('locamos.vn')
										? item.map_captured
										: `https://media-web.locamos.vn/${item.map_captured}`
									: null;

								popup.setContent(`
									<div class="marker-popup-container">
										${
											img_path 
											? `<img src="${img_path}" alt="" class="img-fluid w-100 marker-img"></img>`
											: ''
										}
										<p class="${item.is_blacklist ? 'mb-0' : ''}">
											<span class="fw-semibold">${item.name}</span><br />
											Chủ sở hữu: ${fullname}<br />
											Latitude: ${item.lat}<br />
											Longitude: ${item.long}<br />
										</p>
										${
											item.is_blacklist
											? ''
											: `<a href="https://nftlocation.vn/${item.handle}" class="btn btn-primary" target="_blank">Xem chi tiết</a>`
										}
									</div>
								`)
							})
					})
				markerList.push(marker);
			});

			// optionally center the map around the markers
			// map.fitBounds(mcg.getBounds());

			// add the marker cluster group to the map
			mcg.addLayers(markerList);
			map.addLayer(mcg);
		} else {
			mcg.clearLayers();
		}
	}, [markers, map]);

	return null;
};

// MarkerCluster.propTypes = {
// 	markers: PropTypes.arrayOf(
// 		PropTypes.shape({
// 			position: PropTypes.objectOf(PropTypes.number).isRequired,
// 			text: PropTypes.string.isRequired
// 		}).isRequired
// 	).isRequired
// };

export default MarkerCluster;