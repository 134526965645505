import { createStore, applyMiddleware } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import thunkMiddleware from 'redux-thunk';
import { createLogger } from 'redux-logger';
import rootReducer from '../reducers';

const persistConfig = {
    key: 'root',
    storage,
    blacklist: []
}

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = () => {
    let store;
    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        // dev code
        const loggerMiddleware = createLogger();
        store = createStore(persistedReducer, applyMiddleware(thunkMiddleware, loggerMiddleware));
    } else {
        // production code
        store = createStore(persistedReducer, applyMiddleware(thunkMiddleware));
    }

    let persistor = persistStore(store);
    return { store, persistor }
}

export default store;