import React from 'react';

import { connect } from 'react-redux';

import { ScreenWithNavBar } from 'app/components';

const mapStateToProps = (state) => {
    const { pageInfo } = state;
    return { pageInfo }
}

const actionCreators = {
}

const Account = connect(mapStateToProps, actionCreators)(props => {

    const { lang, locale_string } = props.pageInfo;

    const showToday = () => {
        let today = new Date().toLocaleString(locale_string, { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' });

        return today
    }
    
    return (
        <ScreenWithNavBar>
            <div className="container-xl">
                <div className="d-flex justify-content-between align-items-sm-center flex-column flex-sm-row mb-4">
                    <div className="me-4 mb-3 mb-sm-0">
                        <h3 className="mb-0">Account</h3>
                        <div className="small">
                            { showToday() }
                        </div>
                    </div>
                </div>
            </div>
        </ScreenWithNavBar>
    )
})

export {
    Account
};