import { pageInfoConstants } from 'app/redux/constants';

const getCurrencyUnit = () => {
    switch (window.location.hostname) {
        default:
            return 'VNĐ';
    }
}

const getDefaultLang = () => {
    switch (window.location.hostname) {
        default:
            return 'vi';
    }
}

const getDefaultLocaleString = () => {
    switch (window.location.hostname) {
        default:
            return 'vi-VN';
    }
}

const getLocaleString = (lang) => {
    switch (lang) {
        case 'en':
            return 'en-US';

        case 'vi':
            return 'vi-VN';

        default:
            return 'vi-VN';
    }
}

const initialState = {
    logo: '/assets/images/app-logo.png',
    brandname: 'LocaMos', 
    slogan: 'Bất động sản thế hệ mới',
    address: 'Số 2 Văn Cao, Phường Thuỵ Khuê, Quận Tây Hồ',
    address_line2: 'Thành phố Hà Nội, Việt Nam',
    phone_number: '1900 9999 67', 
    email: 'support@locamos.com',
    company_name: 'CÔNG TY CỔ PHẦN CÔNG NGHỆ LOCAMOS',
    company_shortname: 'LocaMos',
    company_registration_number: '0109194346',
    company_representative_person: 'VŨ NGỌC QUYẾT',
    company_representative_position: 'Tổng Giám Đốc',
    company_foundation_year: '2020',
    work_hours: "08:30 - 17:45",
    currency_unit: getCurrencyUnit(),
    display_lang: getDefaultLang(),
    locale_string: getDefaultLocaleString(),
    current_path: '/',
}

export function pageInfo(state = initialState, action) {
    switch (action.type) {
        case pageInfoConstants.FETCH_SUCCESS:
            let fetch_time = new Date();
            return {
                ...state,
                ...action.data,
                timestamp: fetch_time.getTime()
            };

        case pageInfoConstants.CHANGE_LANGUAGE:
            return {
                ...state,
                display_lang: action.data,
                locale_string: getLocaleString(action.data)
            };

        case pageInfoConstants.FETCH:
        case pageInfoConstants.FETCH_FAILURE:
        default:
            return state;
    }
}