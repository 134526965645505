import React from 'react';
import './Layout.scss';
import { text } from 'app/localization';

import { connect } from 'react-redux';
import { useLocation, Link } from 'react-router-dom';
import { NAVIGATION } from 'config';

import { pageInfoActions } from 'app/redux/actions';

const mapStateToProps = (state) => {
    const { pageInfo, auth } = state;
    return { pageInfo, auth }
}

const actionCreators = {
    changeLang: pageInfoActions.change_lang
}

const ScreenWithNavBar = props => {

    return (
        <div className="layout-navbar">

            {/* Top Navigation Bar */}
            <TopNavBar />

            {/* Page Content */}
            <div className="site-content">
                {props.children}
            </div>

        </div>
    )
}

const ScreenWithNavBarAndFooter = props => {

    return (
        <div className="layout-navbar">

            {/* Top Navigation Bar */}
            <TopNavBar />

            {/* Page Content */}
            <div className="site-content">
                {props.children}
            </div>

            {/* Footer */}
            <Footer />

        </div>
    )
}

const TopNavBar = connect(mapStateToProps, actionCreators)(props => {
    const location = useLocation();
    const { pageInfo, changeLang } = props;
    const lang = pageInfo.display_lang;
    const { isLogin, user } = props.auth;

    return (
        <nav className="navbar navbar-expand-lg navbar-dark bg-dark fixed-top shadow top-navbar">
            <div className="container-fluid">
                <button className="navbar-toggler me-auto order-1" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent">
                    <i className="far fa-bars"></i>
                </button>
                <Link className="navbar-brand order-2" to={NAVIGATION.DASHBOARD}>
                    <img className="" src="https://locamos.vn/assets/images/header-logo.png" alt=""></img>
                </Link>
                <div className="collapse navbar-collapse order-4 order-lg-3" id="navbarSupportedContent">
                    <ul className="navbar-nav ms-auto">
                        <li className="nav-item">
                            <a className="nav-link active" aria-current="page" href="https://locamos.vn" target="_blank">Trang chủ</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="https://find.locamos.vn/" target="_blank">NFTLocation</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="https://sme.locamos.vn/" target="_blank">Chương trình SME</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="https://download.locameet.vn/" target="_blank">Tải App</a>
                        </li>
                    </ul>
                </div>
                {/* <ul className="navbar-nav ms-auto ms-lg-3 order-3 order-lg-4">
                    <li className="nav-item dropdown navbar-user-dropdown">
                        <button className="nav-link dropdown-toggle" id="navbarDropdown" data-bs-toggle="dropdown" aria-expanded="true">
                            <img src="https://sb-admin-pro.startbootstrap.com/assets/img/illustrations/profiles/profile-1.png" alt=""></img>
                        </button>
                        <ul className="dropdown-menu dropdown-menu-end shadow" aria-labelledby="navbarDropdown">
                        {
                            isLogin
                            ? <>
                                <li><div className="navbar-user">
                                    <div className="navbar-user-avatar">
                                        <img src="https://sb-admin-pro.startbootstrap.com/assets/img/illustrations/profiles/profile-1.png" alt=""></img>
                                    </div>
                                    <div className="navbar-user-info">
                                        <p className="navbar-user-fullname">{user.fullname}</p>
                                        <p className="navbar-user-username">{user.email ? user.email : user.fullname}</p>
                                    </div>
                                </div></li>
                                <li><hr className="dropdown-divider"></hr></li>
                                <li><Link className="dropdown-item" to={NAVIGATION.ACCOUNT}><i className="fal fa-fw fa-user-circle"></i>{text.top_nav_bar.account[lang]}</Link></li>
                                <li><Link className="dropdown-item" to={NAVIGATION.LOGOUT}><i className="fal fa-fw fa-sign-out"></i>{text.top_nav_bar.logout[lang]}</Link></li>
                            </>
                            : <>
                                <li><Link className="dropdown-item" to={NAVIGATION.ACCOUNT}><i className="fal fa-fw fa-user-circle"></i>{text.top_nav_bar.account[lang]}</Link></li>
                                <li><Link className="dropdown-item" to={NAVIGATION.LOGIN}><i className="fal fa-fw fa-sign-in"></i>{text.top_nav_bar.login[lang]}</Link></li>
                            </>
                        }
                        </ul>                      
                    </li>
                </ul> */}
            </div>
        </nav>
    )
})

const Footer = connect(mapStateToProps, actionCreators)(props => {
    const { pageInfo } = props;
    const lang = pageInfo.display_lang;
    
    return (
        <footer className="site-footer">
            <div className="container-xl">
                {/* <div className="footer-brand" to={NAVIGATION.DASHBOARD}>{pageInfo.company_shortname}</div> */}
                <div className="row">
                    <div className="col-sm-6">
                        <h5 className="mb-3">{pageInfo.company_name}</h5>
                        <p className="mb-5 mb-sm-0">
                            {pageInfo.address}<br />
                            {pageInfo.address_line2 ? <>{pageInfo.address_line2}<br /></> : null}
                            Hotline: <a href={`tel:${pageInfo.phone_number}`}>{pageInfo.phone_number}</a><br />
                            Email: <a href={`mailto:${pageInfo.email}`}>{pageInfo.email}</a><br />
                            Số giấy chứng nhận ĐKKD: {pageInfo.company_registration_number}<br />
                            {/* Người đại diện theo pháp luật: {pageInfo.company_representative_person}<br />
                            Chức vụ: {pageInfo.company_representative_position} */}
                        </p>
                    </div>
                    <div className="col-sm-3">
                        <h5 className="mb-3">THÔNG TIN</h5>
                        <ul className="footer-links">
                            {/* <li><Link to={NAVIGATION.TERMS_OF_SERVICE}>{text.footer.links.tos[lang]}</Link></li>
                            <li><Link to={NAVIGATION.PRIVACY_POLICY}>{text.footer.links.privacy_policy[lang]}</Link></li>
                            <li><Link to={NAVIGATION.PAYMENT_POLICY}>{text.footer.links.payment_policy[lang]}</Link></li>
                            <li><Link to={NAVIGATION.WEBSITE_OWNER}>{text.footer.links.website_owner[lang]}</Link></li> */}
                            <li><Link to="https://locamos.vn/chinh-sach-quy-dinh-chung.html" target="_blank">{text.footer.links.tos[lang]}</Link></li>
                            <li><Link to="https://locamos.vn/chinh-sach-bao-mat.html" target="_blank">{text.footer.links.privacy_policy[lang]}</Link></li>
                            <li><Link to="https://locamos.vn/chinh-sach-thanh-toan.html" target="_blank">{text.footer.links.payment_policy[lang]}</Link></li>
                            <li><Link to="https://locamos.vn/thong-tin-chu-so-huu.html" target="_blank">{text.footer.links.website_owner[lang]}</Link></li>
                        </ul>
                    </div>
                    <div className="col-sm-3">
                        <h5 className="mb-3">ĐƯỜNG DẪN</h5>
                        <ul className="footer-links">
                            <li><Link to={NAVIGATION.CONTACT_US}>{text.footer.information.contact[lang]} {pageInfo.brandname}</Link></li>
                            <li><Link to={NAVIGATION.ABOUT_US}>{text.footer.information.about[lang]} {pageInfo.brandname}</Link></li>
                            <li><Link to={NAVIGATION.FAQS}>{text.footer.information.faqs[lang]}</Link></li>
                            <li><Link to={NAVIGATION.KEYWORDS}>{text.footer.information.keywords[lang]}</Link></li>
                        </ul>
                    </div>
                </div>
            </div>
            <div id="copyright" className="site-copyright">
                <div className="text-center">{pageInfo.company_name} &copy; {pageInfo.company_foundation_year}</div>
            </div>
        </footer>
    );
})

export {
    ScreenWithNavBar,
    ScreenWithNavBarAndFooter
}