import React from "react";
import { connect } from "react-redux";
import { Navigate, Route } from "react-router";
import { useLocation } from "react-router-dom";
import { NAVIGATION } from 'config';

const PrivateRoute = props => {
    const { isLogin } = props.auth;
    const location = useLocation();

    if ( !isLogin ) {
        return <Navigate to={NAVIGATION.LOGIN} state={{ from: location.pathname }} replace={true} />;
    }
    return props.children;
};

const mapStateToProps = (state) => {
    const { auth } = state;
    return { auth };
}

export default connect(mapStateToProps, null)(PrivateRoute);