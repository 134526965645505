import React, { useState, useEffect } from 'react';
import './Auth.scss';
import { text } from 'app/localization';

import { connect } from 'react-redux';
import { authActions, alertActions, pageInfoActions } from 'app/redux/actions';

import { NAVIGATION } from 'config';
import { useLocation, Link, useNavigate } from 'react-router-dom';

const mapStateToProps = (state) => {
    const { pageInfo, auth } = state;
    return { pageInfo, auth };
}

const actionCreators = {
    login: authActions.login,
    logout: authActions.logout,
    alertError: alertActions.error,
    changeLang: pageInfoActions.change_lang
}

const Login = connect(mapStateToProps, actionCreators)(props => {
    const location = useLocation();
    const navigate = useNavigate();
    const { pageInfo, auth, login, alertError } = props;
    const lang = pageInfo.display_lang;
    const { isLogin, apiRequest, error } = auth;

    const [loginForm, setLoginForm] = useState({
        username: '',
        password: '',
    })

    useEffect(() => {
        if (isLogin) {
            if ( location.state && location.state.from ) {
                navigate(location.state.from, { replace: true });
            } else {
                navigate(NAVIGATION.DASHBOARD, { replace: true });
            }
        }
        if (error) {
            alertError(error);
        }
    }, [isLogin, location, navigate, error, alertError])

    const handleFormChange = (e, input_name) => {
        let value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
        setLoginForm(form_data => ({
            ...form_data,
            [input_name]: value
        }))
    }

    const handleSubmit = e => {
        if (e.keyCode === 13) {
            doLogin();
        } else {
            return null;
        }
    }

    const doLogin = () => {
        if (
            loginForm.username.trim().length === 0
            || loginForm.password.trim().length === 0
        ) {
            alertError(text.auth.login.field_can_not_be_blank[lang]);
        } else {
            login(loginForm.username, loginForm.password);            
        }
    }

    return (
        <div className="page-auth bg-primary bg-gradient">
            <div className="container">
                <div className="auth-logo">BA</div>
                <div className="card auth-form mx-auto">
                    <h5 className="card-header">{text.auth.login[lang]}</h5>
                    <div className="card-body">
                        <div className="mb-3">
                            <label htmlFor="username" className="form-label">{text.auth.login.username[lang]}</label>
                            <input type="text" className="form-control" id="username" name="username" onChange={e => {handleFormChange(e, 'username')}} onKeyUp={handleSubmit}></input>
                        </div>
                        <div className="mb-3">
                            <label htmlFor="password" className="form-label">{text.auth.login.password[lang]}</label>
                            <input type="password" className="form-control" id="password" name="password" onChange={e => {handleFormChange(e, 'password')}} onKeyUp={handleSubmit}></input>
                        </div>
                        <div className="d-flex flex-row flex-nowrap justify-content-between align-items-center mt-4 mb-1">
                            <Link to={NAVIGATION.FORGOT_PASSWORD} className="btn btn-link">{text.auth.login.forgot_password[lang]}</Link>
                            <button type="submit" className="btn btn-primary px-5" onClick={doLogin} disabled={apiRequest}>{text.auth.login[lang]}</button>
                        </div>
                    </div>
                    <div className="card-footer">
                        <div className="d-flex flex-row flex-nowrap justify-content-center">
                            <Link to={NAVIGATION.REGISTER} className="btn btn-link">{text.auth.login.register[lang]}</Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
})

const Register = connect(mapStateToProps, actionCreators)(props => {
    // console.log('Register ', props);
    const location = useLocation();
    const navigate = useNavigate();
    const { pageInfo, auth, alertError } = props;
    const lang = pageInfo.display_lang;
    const { isLogin, apiRequest, error } = auth;

    const [registerForm, setRegisterForm] = useState({})

    useEffect(() => {
        if (isLogin) {
            if ( location.state && location.state.from ) {
                navigate(location.state.from, { replace: true });
            } else {
                navigate(NAVIGATION.DASHBOARD, { replace: true });
            }
        }
        if (error) {
            alertError(error);
        }
    }, [isLogin, location, navigate, error, alertError])

    const handleFormChange = (e, input_name) => {
        let value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
        setRegisterForm(form_data => ({
            ...form_data,
            [input_name]: value
        }))
    }

    const doRegister = () => {
        console.log('Register', registerForm);
    }

    return (
        <div className="page-auth bg-primary bg-gradient">
            <div className="container">
                <div className="auth-logo">BA</div>
                <div className="card auth-form register-form mx-auto">
                    <h5 className="card-header">{text.auth.register[lang]}</h5>
                    <div className="card-body">
                        <div className="row">
                            <div className="col-12">
                                <div className="mb-3">
                                    <label htmlFor="register_fullname" className="form-label">{text.auth.register.full_name[lang]} <span className="text-danger">*</span></label>
                                    <input type="text" className="form-control" name="register_fullname" id="register_fullname"  autoComplete="name" onChange={e => handleFormChange(e, 'fullname')}></input>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="mb-3">
                                    <label htmlFor="register_fullname" className="form-label">{text.auth.register.phone_number[lang]} <span className="text-danger">*</span></label>
                                    <input type="tel" className="form-control" name="register_mobile" id="register_mobile" autoComplete="tel" onChange={e => handleFormChange(e, 'mobile')}></input>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="mb-3">
                                    <label htmlFor="register_email" className="form-label">{text.auth.register.email[lang]}</label>
                                    <input type="email" className="form-control" name="register_email" id="register_email" autoComplete="email" onChange={e => handleFormChange(e, 'email')}></input>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="mb-3">
                                    <label htmlFor="register_password" className="form-label">{text.auth.register.password[lang]} <span className="text-danger">*</span></label>
                                    <input type="password" className="form-control" name="register_password" id="register_password" autoComplete="password" onChange={e => handleFormChange(e, 'password')}></input>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="mb-3">
                                    <label htmlFor="register_city" className="form-label">{text.auth.register.province[lang]}</label>
                                    <select className="form-control" name="register_city" id="register_city" onChange={e => handleFormChange(e, 'province_id')}>
                                        <option value="0">{text.auth.register.please_select[lang]}</option>
                                        {/* {renderProvinceList()} */}
                                    </select>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="mb-3">
                                    <label htmlFor="register_district" className="form-label">{text.auth.register.district[lang]}</label>
                                    <select className="form-control" name="register_district" id="register_district" onChange={e => handleFormChange(e, 'district_id')}>
                                        <option value="0">{text.auth.register.please_select[lang]}</option>
                                        {/* {renderDistrictList(distributorForm.province_id)} */}
                                    </select>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="mb-3">
                                    <label htmlFor="register_address" className="form-label">{text.auth.register.address[lang]}</label>
                                    <input type="text" className="form-control" name="register_address" id="register_address" autoComplete="address"  onChange={e => handleFormChange(e, 'address')}></input>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="mb-3">
                                    <label htmlFor="register_code_ref" className="form-label">{text.auth.register.code_ref[lang]}</label>
                                    <input type="tel" className="form-control" name="register_code_ref" id="register_code_ref" onChange={e => handleFormChange(e, 'code_ref')}></input>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="mb-3">
                                    <div className="custom-control custom-checkbox">
                                        <input type="checkbox" className="custom-control-input me-2" id="customCheck1" checked disabled></input>
                                        <label className="custom-control-label mb-0" htmlFor="customCheck1">Bằng việc đăng ký tài khoản, bạn đồng ý với <Link to={NAVIGATION.TERMS_OF_SERVICE}>Điều khoản dịch vụ</Link> và <Link to={NAVIGATION.PRIVACY_POLICY}>Chính sách bảo mật</Link> của chúng tôi.</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <button type="submit" className="btn d-block w-100 btn-primary mt-2 mb-1" onClick={doRegister} disabled={apiRequest}>{text.auth.register.register[lang]}</button>
                    </div>
                    <div className="card-footer">
                        <div className="d-flex flex-row flex-nowrap justify-content-center">
                            <Link to={NAVIGATION.LOGIN} className="btn btn-link p-0">{text.auth.return_to_login[lang]}</Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
})

const ForgotPassword = connect(mapStateToProps, actionCreators)(props => {
    // console.log('ForgotPassword ', props);
    const location = useLocation();
    const navigate = useNavigate();
    const { pageInfo, auth, alertError } = props;
    const lang = pageInfo.display_lang;
    const { isLogin, apiRequest, error } = auth;

    const [loginForm, setLoginForm] = useState({
        username: '',
    })

    useEffect(() => {
        if (isLogin) {
            if ( location.state && location.state.from ) {
                navigate(location.state.from, { replace: true });
            } else {
                navigate(NAVIGATION.DASHBOARD, { replace: true });
            }
        }
        if (error) {
            alertError(error);
        }
    }, [isLogin, location, navigate, error, alertError])

    const handleFormChange = (e, input_name) => {
        let value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
        setLoginForm(form_data => ({
            ...form_data,
            [input_name]: value
        }))
    }

    const handleSubmit = e => {
        if (e.keyCode === 13) {
            doForgetPassword();
        } else {
            return null;
        }
    }

    const doForgetPassword = () => {
        if (
            loginForm.username.trim().length === 0
        ) {
            alertError(text.auth.forgot_password.field_can_not_be_blank[lang]);
        } else {
            console.log('Forget Password', loginForm);
        }
    }

    return (
        <div className="page-auth bg-primary bg-gradient">
            <div className="container">
                <div className="auth-logo">BA</div>
                <div className="card auth-form mx-auto">
                    <h5 className="card-header">{text.auth.forgot_password[lang]}</h5>
                    <div className="card-body">
                        <div className="mb-3">
                            <label htmlFor="username" className="form-label">{text.auth.login.username[lang]}</label>
                            <input type="text" className="form-control" id="username" name="username" onChange={e => {handleFormChange(e, 'username')}} onKeyUp={handleSubmit}></input>
                        </div>
                        <button type="submit" className="btn d-block w-100 btn-primary mt-4 mb-1" onClick={doForgetPassword} disabled={apiRequest}>{text.auth.forgot_password.send_request[lang]}</button>
                    </div>
                    <div className="card-footer">
                        <div className="d-flex flex-row flex-nowrap justify-content-center">
                            <Link to={NAVIGATION.LOGIN} className="btn btn-link">{text.auth.return_to_login[lang]}</Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
})

const Logout = connect(mapStateToProps, actionCreators)(props => {
    const navigate = useNavigate();
    const { logout } = props;

    useEffect(() => {
        logout();
        navigate(NAVIGATION.DASHBOARD, { replace: true });
    }, [logout, navigate]);

    return null;
})

export {
    Login,
    Register,
    ForgotPassword,
    Logout
}