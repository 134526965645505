import React from 'react';
import { Navigate, Routes, Route } from 'react-router-dom';

import { NAVIGATION } from 'config';
import PrivateRoute from "./PrivateRoute";

//Screen imports
import { 
    Dashboard,
    Login, Register, ForgotPassword, Logout,
    Account
} from 'app/screens';

export default function AppNavigator() {
    return (
        <Routes>
            <Route exact path={NAVIGATION.DASHBOARD} element={<Dashboard />} />

            <Route path={NAVIGATION.LOGIN} element={<Login />} />
            <Route path={NAVIGATION.FORGOT_PASSWORD} element={<ForgotPassword />} />
            <Route path={NAVIGATION.REGISTER} element={<Register />} />
            <Route path={NAVIGATION.LOGOUT} element={<Logout />} />

            <Route path={NAVIGATION.ACCOUNT} element={
                <PrivateRoute>
                    <Account />
                </PrivateRoute>
            } />
            
            <Route index path="*" element={<NoMatch />} />
        </Routes>
    )
}

const NoMatch = props => {
    return <Navigate to={NAVIGATION.DASHBOARD} replace={true} />
}