import React, { useEffect, useState, useRef } from 'react';
import './Dashboard.scss';
import { text } from 'app/localization';

import { connect } from 'react-redux';
import { alertActions, authActions } from 'app/redux/actions';

import { NAVIGATION, GG_MAPS_KEY } from 'config';

import { ScreenWithNavBarAndFooter, MarkerCluster } from 'app/components';

import {
    MapContainer,
    TileLayer,
	useMap,
	Marker,
	Popup,
	useMapEvent,
} from 'react-leaflet';

import * as L from "leaflet";

import { homepageService } from 'services';

import haversine from 'haversine-distance';

import { Modal } from 'bootstrap';

import { Loader } from "@googlemaps/js-api-loader";

var geocoder, google_maps_api_loaded = false;

const LocationMarker = ({ defaultPostion, positionList, alertError, alertSuccess, lang }) => {
	const [position, setPosition] = useState(defaultPostion);
	const [address, setAddress] = useState(null)
	const pop = useRef(null);
	const map = useMap();
	var showPopupTimeout;

	useEffect(() => {
		setPosition({ lat: defaultPostion.lat, lng: defaultPostion.lng});
		setAddress(defaultPostion.address);

		return () => {
			setPosition({ lat: null, lng: null })
			setAddress(null);
		}
	}, [defaultPostion, setPosition, setAddress]);
	

	useEffect(() => {
		clearTimeout(showPopupTimeout);

		if (pop.current && position.lat && position.lng) {
			showPopupTimeout = setTimeout(() => {
				try {
					pop.current.openOn(map);
				} catch (error) {
					console.log(error);
				}
			}, 200)
		}
	}, [position, pop])
	
	
	const mapEvent = useMapEvent('click', (e) => {
		setPosition(e.latlng);
		setAddress(null);

		// homepageService.search_place(e.latlng.lat + ',' + e.latlng.lng)
		// 	.then(data => {
		// 		if (data) {
		// 			setAddress(data[0].formatted_address);
		// 		} else {
		// 			setAddress(null);
		// 		}
		// 	});
	})

	const marker_picker = L.icon({
		iconUrl: 'public_assets/images/map-marker-picker.png',
		iconSize: [64, 70],
		iconAnchor: [32, 53],
		popupAnchor: [0, -40]
	});

	function handleSelectPos() {
		let start_lat = position.lat - 0.0025,
			end_lat = position.lat + 0.0025,
			start_lng = position.lng + 0.0025,
			end_lng = position.lng - 0.0025;

		// use Google Maps Javascript API
		function reverse_geocode(location) {
			geocoder.geocode({ location }, function (results, status) {
				if (status == 'OK') {
					setAddress(results[0].formatted_address);
				} else {
					setAddress(null);
				}
			});
		}

		let nearby_pos = positionList.filter(item => item.lat > start_lat && item.lat < end_lat && item.long < start_lng && item.long > end_lng);
		console.log('?????? nearby_pos', nearby_pos);
		let check_distance = false;
		if (nearby_pos.length < 1) {
			// alertSuccess('LOCAL CHECK OK');
			console.log('LOCAL CHECK OK');
			homepageService.validate_location({ lat: position.lat, long: position.lng })
			.then(res => {
				if (res) {
					console.log('OK', check_distance);
					alertSuccess(text.dashboard.selected_position_validate_pass[lang]);

					if (google_maps_api_loaded) {
						reverse_geocode(position);
					} else {
						setTimeout(() => {
							reverse_geocode(position);
						}, 2000);
					}

				} else {
					console.log('FAIL', check_distance);
					alertError(text.dashboard.selected_position_validate_fail[lang]);
				}
			})
		} else {
			let i = 0;
			do {
				let distance = haversine({ lat: nearby_pos[i].lat, lng: nearby_pos[i].long }, position);
				let min_distance = nearby_pos[i].is_blacklist ? 300 : 200;
				console.log('?????? NFT Location check min-' + min_distance + 'm: ' + check_distance + '. Pos: [' + nearby_pos[i].lat + ', ' + nearby_pos[i].long + ']: ' + distance);
				if (distance > min_distance) {
					check_distance = false;
					++i;
				} else {
					check_distance = true;
				}
			} while (!check_distance && i<nearby_pos.length)

			if (check_distance) {
				console.log('FAIL', check_distance);
				alertError(text.dashboard.selected_position_validate_fail[lang]);
			} else {
				// alertSuccess('LOCAL CHECK OK');
				console.log('LOCAL CHECK OK');
				homepageService.validate_location({ lat: position.lat, long: position.lng })
				.then(res => {
					if (res) {
						console.log('OK', check_distance);
						alertSuccess(text.dashboard.selected_position_validate_pass[lang]);

						if (google_maps_api_loaded) {
							reverse_geocode(position);
						} else {
							setTimeout(() => {
								reverse_geocode(position);
							}, 2000);
						}

					} else {
						console.log('FAIL', check_distance);
						alertError(text.dashboard.selected_position_validate_fail[lang]);
					}
				})
			}
		}
	}

	return !position.lat || !position.lng
	? (
		<Marker position={position} icon={marker_picker}></Marker>
	)
	: (
		<Marker position={position} icon={marker_picker}>
			<Popup className="marker-popup-custom" ref={pop}>
				<div className="marker-popup-container">
					<p className="mt-1">Vị trí đang chọn: <span className="fw-bold">{address || ''}</span><br />{position.lat || '...'}, {position.lng || '...'}</p>
					<button className="btn btn-primary" onClick={handleSelectPos}>Chọn</button>
				</div>
			</Popup>
		</Marker>
	)
}

const MapCenterUpdater = ({ position, zoom }) => {
	const map = useMap();
	useEffect(() => {
		map.setView(position, zoom);
	}, [map, position, zoom])	
}

const NFTMaps = ({ lang, country, alertError, alertSuccess }) => {
	const mapDefaultCenterPos = [21.02274,105.836964];

	const [loading, setLoading] = useState(false);
	const [markers, setMarkers] = useState([]);
	const [mapType, setMapType] = useState('m');
	const [mapCenterPos, setMapCenterPos] = useState({ lat: mapDefaultCenterPos[0], lng: mapDefaultCenterPos[1] });
	const [mapZoomLevel, setMapZoomLevel] = useState(13);
	const [mapSearchString, setMapSearchString] = useState('');
	const [mapSearchPrevString, setMapSearchPrevString] = useState('');
	const [mapSearchResult, setMapSearchResult] = useState([]);
	const [mapSearchResultShow, setMapSearchResultShow] = useState(false);

	const [markerPickerDefaultPos, setMarkerPickerDefaultPos] = useState({ lat: null, lng: null, address: '' });

	const [ggAPILoaded, setGgAPILoaded] = useState(false);

	const loader = new Loader({
		apiKey: GG_MAPS_KEY,
		version: "weekly",
		authReferrerPolicy: "origin"
	});

	useEffect(() => {
		console.log('markerPickerDefaultPos', !markerPickerDefaultPos.lat);
		loader.load().then(async () => {
			google_maps_api_loaded = true;
			setGgAPILoaded(true);
			geocoder = new window.google.maps.Geocoder();
		});
	}, [])

	useEffect(() => {
		if (country) {
			setLoading(true);
			setMapCenterPos(country.position);

			homepageService.fetch_list_place(country.position.lat+','+country.position.lng, country.short_name)
			.then(data => {
				if (data) {
					// console.log(data);
					setMarkers(data);
				} else {
					setMarkers([]);
				}
				setLoading(false);
			})
		}

		return () => {
			setMarkers([]);
		}
	}, [country, setLoading, setMarkers]);	

	function handleMapTypeChange(e) {
		let map_type = e.target.value;
		setMapType(map_type);
	}

	function handleMapSearchStringInput(e) {
		setMapSearchString(e.target.value);
		setMapSearchResultShow(false);
	}

	function handleMapSearchEnter(e) {
		if(e.keyCode === 13) {
			submitSearch();
		}
	}

	function submitSearch() {
		console.log('Search: ' + mapSearchString);
		if (mapSearchString !== mapSearchPrevString) {
			
			// use Google Maps Javascript API
			geocoder.geocode({ address: mapSearchString }, function (results, status) {
				if (status == 'OK') {
					setMapSearchPrevString(mapSearchString);
					setMapSearchResult(results);
					setMapSearchResultShow(true);
				} else {
					setMapSearchResult([]);
					console.log('Geocode was not successful for the following reason: ' + status);
					alertError(text.dashboard.map_search_result_empty[lang]);
				}
			});

			// homepageService.search_place(mapSearchString)
			// .then(data => {
			// 	if (data) {
			// 		setMapSearchPrevString(mapSearchString);
			// 		setMapSearchResult(data);
			// 		setMapSearchResultShow(true);
			// 	} else {
			// 		setMapSearchResult([]);
			// 		alertError(text.dashboard.map_search_result_empty[lang]);
			// 	}
			// });
		} else {
			setMapSearchResultShow(true);
		}
	}

	function renderMapSearchResults(list) {
		if (!list || list.length === 0) {
			return null;
		}
		// console.log(list);

		function zoomMapToPos(lat, lng, address) {
			setMapCenterPos([lat, lng]);
			setMapZoomLevel(15);
			setMarkerPickerDefaultPos({lat, lng, address });
			setMapSearchResultShow(false);
		}

		let res = [];
		list.forEach((item, index) => {
			res.push(
				<div key={'mapSearchRes_'+index} className="map-search-result-item" onClick={() => { zoomMapToPos(item.geometry.location.lat(), item.geometry.location.lng(), item.formatted_address) }}>
					{item.formatted_address} ({item.geometry.location.lat()}, {item.geometry.location.lng()})
				</div>
			)
		})

		return <>{res}</>;
	}

	return (
		<div id="map">
			<div className={`map-loading-indicator ${ loading ? 'show' : '' }`}>
				Loading map data... <i className="far fa-cog fa-spin"></i>
			</div>
			{
				country && !loading
				? <button className="btn btn-sm btn-light bg-white btn-select-country-on-maps" data-bs-toggle="modal" data-bs-target="#selectCountryModal">
					<span className="select-country-flag" style={{ backgroundImage: 'url("' + country.flag_image + '")' }}></span>
					<span className="select-country-label">{country.display_name}</span>
				</button>
				: <></>
			}
			<div className="map-search-box">
				<div className="input-group">
					<input type="text" className="form-control form-control-lg bg-white" id="mapSearchInput" placeholder={text.dashboard.map_search_input_placeholder[lang]} onChange={handleMapSearchStringInput} onKeyDown={handleMapSearchEnter}></input>
					<button className="btn btn-lg btn-primary btn-icon" type="button" id="button-addon2" onClick={submitSearch}><i className="far fa-search"></i></button>
				</div>
				<div className={`map-search-results ${ mapSearchResultShow ? 'show' : '' }`}>
					{ renderMapSearchResults(mapSearchResult) }
				</div>
			</div>
			<div className="map-style-selector">
				<div className="form-check form-check-inline">
					<input className="form-check-input d-none" type="radio" name="mapTypeSelector" id="mapTypeStreet" value="m" defaultChecked="true" onChange={handleMapTypeChange}></input>
					<label className="form-check-label" htmlFor="mapTypeStreet">
						<img src="public_assets/images/map-type-street.png" alt="" className="img-fluid map-style-img"></img>
						<span className="map-style-label">{text.dashboard.map_type_street[lang]}</span>
					</label>
				</div>
				<div className="form-check form-check-inline">
					<input className="form-check-input d-none" type="radio" name="mapTypeSelector" id="mapTypeSatelite" value="s,h" onChange={handleMapTypeChange}></input>
					<label className="form-check-label" htmlFor="mapTypeSatelite">
						<img src="public_assets/images/map-type-satelite.png" alt="" className="img-fluid map-style-img"></img>
						<span className="map-style-label text-white">{text.dashboard.map_type_satelite[lang]}</span>
					</label>
				</div>
			</div>
			<MapContainer className="nft-maps" center={mapDefaultCenterPos} zoom={13} scrollWheelZoom={true} zoomControl={false} attributionControl={false} preferCanvas={true}>
				<TileLayer
					attribution=''
					// url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
					// url="https://maps.hereapi.com/v3/base/mc/{z}/{x}/{y}/png?apiKey=Bt771_BaSy5e0qyE7qowpIcNoiJ9lIqgk3zfmkJdso4"
					url={`https://{s}.google.com/vt/lyrs=${mapType}&x={x}&y={y}&z={z}`}
					subdomains={['mt0','mt1','mt2','mt3']}
				/>
				<MarkerCluster markers={markers} />
				{
					ggAPILoaded
					? <LocationMarker defaultPostion={markerPickerDefaultPos} positionList={markers} alertError={alertError} alertSuccess={alertSuccess} lang={lang} />
					: null
				}				
				<MapCenterUpdater position={mapCenterPos} zoom={mapZoomLevel} />
			</MapContainer>
		</div>
	)
}

const Dashboard = props => {
    const { history, location, pageInfo, alertError, alertSuccess } = props;
    const lang = pageInfo.display_lang;

	const [countryList, setCountryList] = useState([
		{
			short_name: 'VN',
			long_name: 'Vietnam',
			display_name: 'Việt Nam (Vietnam)',
			flag_image: 'https://upload.wikimedia.org/wikipedia/commons/thumb/2/21/Flag_of_Vietnam.svg/255px-Flag_of_Vietnam.svg.png',
			position: { lat: '21.02274', lng: '105.836964' }
		},
		// {
		// 	short_name: 'TH',
		// 	long_name: 'Thailand',
		// 	display_name: 'ประเทศไทย (Thailand)',
		// 	flag_image: 'https://upload.wikimedia.org/wikipedia/commons/thumb/a/a9/Flag_of_Thailand.svg/255px-Flag_of_Thailand.svg.png',
		// 	position: { lat: '13.7563309', lng: '100.5017651' }
		// }
	])
	const [country, setCountry] = useState(null);
	const [center, setCenter] = useState(null);

	useEffect(() => {
		if (!country) {
			if (countryList.length === 1) {
				setCountry(countryList[0]);
				setCenter(countryList[0].position);
			} else {
				let selectCountryModal = new Modal('#selectCountryModal');
				selectCountryModal.show();
			}
		}

		return () => {
			// setCountry(null);
		}
	}, [country])

	function renderCountryList(list) {
		if (!list || list.length === 0) return null;

		function handleSelectCountry(country) {
			// let selectCountryModal = new Modal('#selectCountryModal');
			// selectCountryModal.hide('hide');
			document.getElementsByClassName('modal-backdrop')[0].remove();
			setCountry(country);
			setCenter(country.position);
		}

		let countries = [];
		list.forEach((item, index) => {
			countries.push(
				<div className="select-country-item" key={'selCountry'+index}>
					<button className="btn btn-outline-secondary" onClick={() => { handleSelectCountry(item) }} data-bs-dismiss="modal">
						<span className="select-country-flag" style={{ backgroundImage: 'url("' + item.flag_image + '")' }}></span>
						<span className="select-country-label">{item.display_name}</span>
					</button>
				</div>
			)
		})

		return countries;
	}

    return (
        <ScreenWithNavBarAndFooter>
			<NFTMaps lang={lang} country={country} center={center} alertError={alertError} alertSuccess={alertSuccess} />

			<div className="modal fade" id="selectCountryModal" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="selectCountryModalLabel" aria-hidden="true">
				<div className="modal-dialog modal-dialog-centered">
					<div className="modal-content">
						<div className="modal-header">
							<h1 className="modal-title fs-5" id="selectCountryModalLabel">Chọn quốc gia</h1>
						</div>
						<div className="modal-body">
							<div className="select-country-list">
								{ renderCountryList(countryList) }
							</div>
						</div>
					</div>
				</div>
			</div>
        </ScreenWithNavBarAndFooter>
    )
}

const mapStateToProps = (state) => {
    const { pageInfo } = state;
    return { pageInfo }
}

const actionCreators = {
	alertError: alertActions.error,
	alertSuccess: alertActions.success
}

export default connect(mapStateToProps, actionCreators)(Dashboard);