import { authConstants } from 'app/redux/constants';

const initialState = {
    apiRequest: false,
	isLogin: false,
    user: null,
    isLoading: false,
    error: null
}

export function auth(state = initialState, action) {
	switch (action.type) {
		case authConstants.LOGIN_REQUEST:
            return {
                apiRequest: true,
                isLogin: false,
                user: action.user,
                isLoading: true,
                error: null
            }
        case authConstants.LOGIN_SUCCESS:
            return {
                apiRequest: false,
                isLogin: true,
                user: action.user,
                isLoading: false,
                error: null
            }
        case authConstants.LOGIN_FAILURE:
            return {
                ...initialState,
                error: action.error
            };

        case authConstants.LOGOUT:
            return initialState;
            
		default:
			return state
	}
}