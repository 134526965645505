import { NAVIGATION } from './routes';

// LIVE
const BASE_API = 'https://find.locamos.vn/api'

// DEV
// const BASE_API = 'https://api-dev.locamos.vn/api'

const HOMEPAGE = 'https://globe.locamos.vn/'

// LIVE
const GG_MAPS_KEY = 'AIzaSyB9wQSuIKLX1HTLk6nVIFs_cN6H4tqcvHM'

// DEV
// const GG_MAPS_KEY = 'AIzaSyC6JpN4toASvVoAZLBvpcY_qMYdQTLzBAs'

export {
    BASE_API,
    HOMEPAGE,
    NAVIGATION,
    GG_MAPS_KEY
}