import { authConstants } from 'app/redux/constants';
import { authService } from 'services';

const login = (username, password) => {
    return dispatch => {
        dispatch(request({ username }));

        authService.login(username, password)
            .then(data => {
                // console.log('??????', typeof data);
                if (typeof data === 'object') {
                    dispatch(success(data));
                } else {
                    dispatch(failure(data))
                }
            })
    }

    function request(user) { return { type: authConstants.LOGIN_REQUEST, user } }
    function success(user) { return { type: authConstants.LOGIN_SUCCESS, user } }
    function failure(error) { return { type: authConstants.LOGIN_FAILURE, error } }
}

const logout = () => {
    return dispatch => {
        dispatch({ type: authConstants.LOGOUT });
    }
}

export const authActions = {
    login,
    logout
}